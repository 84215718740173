import "./Navbar.css";
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';
import { useRef } from "react";
import { Menu } from 'primereact/menu';
import { Link, useNavigate } from "react-router-dom";
//@ts-ignore react-router-hash-link is a JS module instead of TS module
import { HashLink } from 'react-router-hash-link';
import React from "react";

export default function Navbar(props: {
    hideButtons?: boolean
}) {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const navigate = useNavigate();
    const mobileNavMenu = useRef<Menu>(null);
    const mobileNavMenuItems = [
        {
            label: 'Sign In',
            icon: 'pi pi-sign-in',
            command: () => navigate('/login')
        },
        {
            label: 'Request Demo',
            icon: 'pi pi-question-circle',
            url: '/#contact',
        }
    ]

    return (
        <div className="navbar">
            <div className="navbar__left">
                <Link to="/">
                    <img 
                        src="/ArchiLabs Logo.png" 
                        alt="Company Logo" 
                    />
                </Link>
            </div>
            {
                props.hideButtons ?
                null
                :
                isMobile ?
                    <div className="navbar__right">
                        <Menu model={mobileNavMenuItems} popup ref={mobileNavMenu} />
                        <Button icon="pi pi-bars" severity="secondary" text 
                            onClick={(event) => mobileNavMenu.current?.toggle(event)}
                        />
                    </div>
                    :
                    <div className="navbar__right">
                        <Link to="/login">
                            <Button label="Sign In" severity="secondary" text />
                        </Link>
                        <HashLink to="/#contact">
                            <Button label="Request Demo" severity="secondary" outlined />    
                        </HashLink>
                    </div>
            }
        </div>
    )   
}