import React from "react"
import "./ProductDescriptionSection.css"
import { useMediaQuery } from "react-responsive"
import { HashLink } from "react-router-hash-link"

export default function ProductDescriptionSection() {

    const isWideMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isNarrowMobile = useMediaQuery({ query: '(max-width: 425px)' })

    return (
        <div className="product-description-section">
            <h2 className="product-description-section__headline">
                New Dimension, New Possibilities
            </h2>
            <div className="product-description-section__subsection">
                <div className="product-description-section__subsection__textbox">
                    <h3 className="product-description-section__subsection__textbox__meta-headline">
                        3D INTERACTIVITY
                    </h3>
                    <h3 className="product-description-section__subsection__textbox__headline">
                        <span style={{color: "#2C7BFF"}}>Visualize</span> custom options in 3D
                    </h3>
                    <p className="product-description-section__subsection__textbox__description">
                        Unlike traditional 2D interactive floorplans and static 3D scans, our interactive 3D plan lets your homebuyers visualize their custom layout selections in real-time.
                    </p>
                </div>
                <div className="product-description-section__subsection__image-container" >
                    <img
                        className="product-description-section__subsection__image" 
                        src="/images/ui-2d-3d-split.png" 
                        alt="2D and 3D Floorplan Split" 
                    />
                </div>
            </div>
            <div className="product-description-section__subsection">
                {
                    !isWideMobile ?
                    <div className="product-description-section__subsection__image-container" >
                        <img
                            className="product-description-section__subsection__image" 
                            src="/images/ui-kitchen-theme-selection.png" 
                            alt="Kitchen Theme Selection" 
                        />
                    </div>
                    :
                    null
                }
                <div className="product-description-section__subsection__textbox">
                    <h3 className="product-description-section__subsection__textbox__meta-headline">
                        AI-GUIDED CUSTOMIZATION
                    </h3>
                    <h3 className="product-description-section__subsection__textbox__headline">
                        <span style={{color: "#01CC4C"}}>Guide</span> your customers without overwhelming them
                    </h3>
                    <p className="product-description-section__subsection__textbox__description">
                    With the help of our AI-powered design assistant, homebuyers can creatively explore various themes and personalized choices to their hearts' content, without being overwhelmed by the paradox of choice. 
                    </p>
                </div>
                {
                    isWideMobile ?
                    <div className="product-description-section__subsection__image-container" >
                        <img
                            className="product-description-section__subsection__image" 
                            src="/images/ui-kitchen-theme-selection.png" 
                            alt="Kitchen Theme Selection" 
                        />
                    </div>
                    :
                    null
                }
            </div>
            <div className="product-description-section__subsection">
                <div className="product-description-section__subsection__textbox">
                    <h3 className="product-description-section__subsection__textbox__meta-headline">
                        PERSONALIZED LIBRARY
                    </h3>
                    <h3 className="product-description-section__subsection__textbox__headline">
                        Be the <span style={{color: "#7B61FF"}}>repository</span> of your prospects’ dreams
                    </h3>
                    <p className="product-description-section__subsection__textbox__description">
                        Let your prospects imagine their dream homes in 3D based on your floorplan templates and save their customized plans exclusively on your website.
                        <br />
                        <br />
                        Our 3D interactive tool invites users to invest their time in personalizing their space, fostering emotional attachment and improving lead conversion
                    </p>
                </div>
                <div className="product-description-section__subsection__image-container" >
                    <img
                        className="product-description-section__subsection__image" 
                        src="/images/ui-personalized-library.png" 
                        alt="Personalized Library" 
                    />
                </div>
            </div>
            <HashLink to="/#contact" style={{textDecoration: "none"}}>
                <button className="hero-section__cta">
                    <span className="hero-section__cta__text">
                        SEE IT IN ACTION
                    </span>
                    <i className="pi pi-play hero-section__cta__icon"></i>
                </button>
            </HashLink>
        </div>
    )
}