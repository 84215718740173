import React from "react";
import "./FooterSection.css";

export default function FooterSection() {
    return (
        <div className="footer-section-container">
            <div className="footer-section">
                <div className="footer-section__left">
                    <img src="/ArchiLabs Logo.png" alt="Company Logo" />
                </div>
                <div className="footer-section__right">
                    <span className="footer-section__right__text">
                        © 2024 ArchiLabs. All rights reserved.
                    </span>
                </div>
            </div>
        </div>
    )
}