import "./VisionSection.css"
import {ReactComponent as ArchitecturalDesignIcon} from "../svg-assets/floorplan-icon.svg"
import { ReactComponent as EngineeringIcon } from "../svg-assets/engineering-icon.svg"
import { ReactComponent as ConstructionIcon } from "../svg-assets/construction-icon.svg"
import { ReactComponent as AfterSaleIcon } from "../svg-assets/home-maintanence-icon.svg"
import React from "react"

export default function VisionSection() {
    return (
        <div className="vision-section__wrapper">
        <div className="vision-section">
            <h2 className="vision-section__headline">
                Shaping the Future of Homebuilding
            </h2>
            <p className="vision-section__description">
                At ArchiLabs, we envision a future where prospective homeowners can intuitively design their dream homes using a simple, yet comprehensive, web-based 3D tool. 
                <br />
                <br />
                A future where every step of the home building lifecycle, from architectural design to construction and even after-sale services, can be empowered and streamlined by an intelligent and context-aware software system.
            </p>
            <div className="vision_section__infographic">
                <div className="vision_section__infographic__subsection top-left">
                <ArchitecturalDesignIcon className="vision_section__infographic__subsection__icon" />
                    <h4 className="vision_section__infographic__subsection__headline">
                        Architectural Design
                    </h4>
                    <p className="vision_section__infographic__subsection__description">
                        Template development
                        <br />
                        Buyer-initiated customization
                    </p>
                </div>
                <div className="vision_section__infographic__subsection top-right">
                    <EngineeringIcon className="vision_section__infographic__subsection__icon" />
                    <h4 className="vision_section__infographic__subsection__headline">
                        Engineering
                    </h4>
                    <p className="vision_section__infographic__subsection__description">
                        Structural engineering
                        <br />
                        Building code compliance
                    </p>
                </div>
                <div className="vision_section__infographic__subsection bottom-left">
                    <ConstructionIcon className="vision_section__infographic__subsection__icon" />
                    <h4 className="vision_section__infographic__subsection__headline">
                        Construction
                    </h4>
                    <p className="vision_section__infographic__subsection__description">
                        Material calculations
                        <br />
                        Fabrication automation
                    </p>
                </div>
                <div className="vision_section__infographic__subsection bottom-right">
                    <AfterSaleIcon className="vision_section__infographic__subsection__icon" />
                    <h4 className="vision_section__infographic__subsection__headline">
                        After-Sale
                    </h4>
                    <p className="vision_section__infographic__subsection__description">
                        Digital twin for maintenance & remodeling planning
                    </p>
                </div>
                <img 
                    src="/images/circular-logo.png" 
                    alt="Antonine Labs Logo" 
                    className="vision_section__infographic__logo"
                />
            </div>
            </div>
        </div>
    )
}