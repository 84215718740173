import React from "react";
import "./HeroSection.css";
//@ts-ignore react-router-hash-link is a JS module instead of TS module
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from "react-responsive";
import { useParallax } from "react-scroll-parallax";

export default function HeroSection() {

    const isWideMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isNarrowMobile = useMediaQuery({ query: '(max-width: 425px)' })

    const { ref: ref_2D_img } = useParallax<HTMLImageElement>({ speed: -10 });
    const { ref: ref_3D_img } = useParallax<HTMLImageElement>({ speed: -10 });
    const { ref: ref_blob_1 } = useParallax<HTMLDivElement>({ speed: -10 });
    const { ref: ref_blob_2 } = useParallax<HTMLDivElement>({ speed: -10 });

    return (
        <div className="hero-section">
            <h1 className="hero-section__title">
                Transform your Online Home Showcase with <span className="hero-section__title__highlight">AI-Powered Interactive 3D Floorplans</span>
            </h1>
            <p className="hero-section__description">
                No more flat, static floorplans. ArchiLabs delivers a dynamic 3D home showcasing experience that comes alive in real-time.
            </p>
            <HashLink to="/#contact" style={{textDecoration: "none"}}>
                <button className="hero-section__cta">
                    <span className="hero-section__cta__text">
                        GET STARTED
                    </span>
                    <i className="pi pi-arrow-up-right hero-section__cta__icon"></i>
                </button>
            </HashLink>
            <img
                ref={ref_2D_img}
                src="/images/hero-section-2d-blurred.png" 
                alt="2D Floorplan Blurred"
                className="hero-section__2d-image"
            />
            <img 
                ref={ref_3D_img}
                src="/images/hero-section-3d-blurred.png" 
                alt="3D Floorplan Blurred"
                className="hero-section__3d-image"
            />
            <div 
                className="hero-section__blob-1" 
                ref={ref_blob_1}
            />
            <div 
                className="hero-section__blob-2"
                ref={ref_blob_2}
            />
        </div>
    )
}